// Units
export const OVER = '/';
export const PER_DAY = '/day';
export const PER_MONTH = '/month';
export const PER_M_CUBE = '/m3';
export const FIELD = 'Field';
export const METRIC = 'Metric';
export const UNIT_OPTIONS = [FIELD, METRIC];

export const STB = 'stb';
export const MSCF = 'Mscf';
export const MMSCF = 'MMscf';
export const SM3 = 'sm3';
export const MSM3 = 'Msm3';
export const MMSM3 = 'MMsm3';
export const RB = 'rb';
export const RM3 = 'rm3';
export const PSIA = 'psia';
export const BARSA = 'barsa';
export const KRB = 'Krb';
export const KPA = 'kPa';
export const FEET = 'ft';
export const METER = 'm';
export const M_CUBE = 'm3';
export const BOE = 'BOE';
export const TOE = 'TOE';
export const INCH = 'Inch';
export const MM = 'mm';
export const FT3 = 'ft3';
export const RBBL = 'rbbl';
export const BBL = 'bbl';

export const UNIT_OIL_EQUIVALENT = { [FIELD]: BOE, [METRIC]: TOE };
export const UNIT_LENGTH = { [FIELD]: FEET, [METRIC]: METER };
export const UNIT_STD_LIQUID = { [FIELD]: STB, [METRIC]: M_CUBE };
export const UNIT_STD_GAS = { [FIELD]: MMSCF, [METRIC]: MMSM3 };
export const UNIT_RES_FLUID = { [FIELD]: RB, [METRIC]: RM3 };
export const UNIT_PRESSURE = { [FIELD]: PSIA, [METRIC]: KPA };
export const UNIT_SIZE = { [FIELD]: INCH, [METRIC]: MM };
export const UNIT_GAS_OIL_RATIO_RATE = {
  [FIELD]: MSCF + OVER + STB,
  [METRIC]: MSM3 + OVER + M_CUBE,
};
export const UNIT_OIL_FORMATION_FACTOR = {
  [FIELD]: RBBL + OVER + STB,
  [METRIC]: RM3 + OVER + M_CUBE,
};
export const UNIT_WATER_FORMATION_FACTOR = {
  [FIELD]: RBBL + OVER + STB,
  [METRIC]: RM3 + OVER + M_CUBE,
};
export const UNIT_GAS_FORMATION_FACTOR = {
  [FIELD]: BBL + OVER + MSCF,
  [METRIC]: M_CUBE + OVER + MSM3,
};

// Well Data
export const BACKEND_DATE_FORMAT = 'DD/MM/YYYY';
export const UI_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE = 'Date'; // For monthly data, this should be the first day of the month
export const YEAR = 'Year';
export const MONTH = 'month';
export const WELL_ID = 'Well Name';
export const XCOL = 'Surface X';
export const YCOL = 'Surface Y';
export const LAYER = 'Layer';
export const SURFACE = 'Surface';
export const RESERVOIR = 'res';
export const ALL_LAYERS = 'All Layers';
export const XCOL_SHIFTED = 'Surface X (shifted)';
export const YCOL_SHIFTED = 'Surface Y (shifted)';
export const IS_INJECTOR_WELL = 'Is Injector Well';
export const ACTIVE_DAYS = 'Active Days';
export const WELL_INFO_COLS = [WELL_ID, XCOL, YCOL, IS_INJECTOR_WELL];
export const GAS_PRODUCTION_RATE = 'Gas Production Rate';
export const OIL_PRODUCTION_RATE = 'Oil Production Rate';
export const WATER_PRODUCTION_RATE = 'Water Production Rate';
export const GAS_INJECTION_RATE = 'Gas Injection Rate';
export const WATER_INJECTION_RATE = 'Water Injection Rate';
export const LIQUID_INJECTION_RATE = 'Liquid Injection Rate';
export const LIQUID_PRODUCTION_RATE = 'Liquid Production Rate';
export const TOTAL_INJECTION_RATE = 'Total Injection Rate (res)';
export const TOTAL_PRODUCTION_RATE = 'Total Production Rate (res)';
export const TOTAL_WELL_RATE = 'Total Well Rate (res)';
export const BOTTOMHOLE_PRESSURE = 'Bottomhole Pressure';
export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';
export const SELECTED_DATE = 'Selected Date';
export const WATER_DRIVE = 'Water Drive';
export const NORMALIZE_ACTIVE_DAYS = 'Normalize by Active Days';
export const DATA_SMOOTHING = 'Data Smoothing';
export const SHUT_IN = 'Shut In';
export const SURVEILLANCE = 'Surveillance';
export const SYSTEM_OF_UNITS = 'System of units';
export const FORMATION_VFO = 'Formation volume factor: Oil';
export const FORMATION_VFW = 'Formation volume factor: Water';
export const FORMATION_VFG = 'Formation volume factor: Gas';
export const OIL_FIT_MODEL = 'Oil Fit Model';
export const POWER_LAW = 'Power law';
export const ADVANCED_POWER_LAW = 'Advanced Power law';
export const KOVAL = 'Koval';
export const MAX_ITERATION = 'Maximum Iteration';
export const TOLERANCE = 'Tolerance';
export const MIN_CONN = 'MIN_CONN';
export const ANGLE_THRESHOLD = 'ANGLE_THRESHOLD';
export const WELL_RATE_THRESHOLD = 'WELL_RATE_THRESHOLD';
export const WATER_CUT_THRESHOLD = 'WATER_CUT_THRESHOLD';
export const MAX_ITERATION_FLUID_MATCHING = 'Max Iteration Fluid Matching';
export const TOLERANCE_FLUID_MATCHING = 'Tolerance Fluid Matching';
export const MAX_ITERATION_OIL_MATCHING = 'Max Iteration Oil Matching';
export const TOLERANCE_OIL_MATCHING = 'Tolerance Oil Matching';
export const MAX_ITERATION_OPTIMISATION = 'Max Iteration Optimisation';
export const TOLERANCE_OPTIMISATION = 'Tolerance Optimisation';
export const MINIMUM_SIMULATIONS = 'Minimum Simulations';
export const MAXIMUM_SIMULATIONS = 'Maximum Simulations';
export const SIMULATION_COUNTER_SIZE = 'Simulation Counter Size';
export const STEPOUT_DISTANCE = 'STEPOUT_DISTANCE';
export const MAX_WELL_LENGTH = 'MAX_WELL_LENGTH';
export const NUMBER_OF_ITERATIONS = 'NUMBER_OF_ITERATIONS';
export const MAX_ITERATION_FUTURE_OPTIMISATION =
  'MAX_ITERATION_FUTURE_OPTIMISATION';
export const TOLERANCE_FUTURE_OPTIMISATION = 'TOLERANCE_FUTURE_OPTIMISATION';
export const TRAIN_TEST_SPLIT = 'Train Test Split';
export const ACTUAL_TOTAL_OIL_RATE = 'Actual total oil rate';
export const ACTUAL_TOTAL_CUM_OIL = 'Actual total cumulative oil';
export const ACTUAL_TOTAL_CUM_OIL_ORG =
  'Actual total cumulative oil - original';
export const CALC_TOTAL_OIL_RATE = 'Calculated total oil rate';
export const CALC_TOTAL_CUM_OIL_OPT =
  'Calculated total cumulative oil - optimized';
export const CALC_TOTAL_CUM_OIL_FTR_YRS =
  'Calculated total cumulative oil w future years';
export const CALC_TOTAL_OIL_RATE_OPT = 'Calculated total oil rate - optimized';
export const CALC_TOTAL_OIL_RATE_FTR_YRS =
  'Calculated total oil rate w future years';
export const OIL_FITTING_OBJECTIVE = 'Oil Fitting Objective';
export const OIL_FITTING_LIQUID_RATE = 'Oil Fitting Liquid Rate';
export const MAX_INJ_MULT = 'Max Injection Multiplier';
export const OPT_R2_THRESHOLD = 'Optimization R2 Threshold';
export const GWR_OPT = 'GWR Based Optimization';
export const GWR_MIN = 'GWR (min)';
export const GWR_MAX = 'GWR (max)';

export const DOG_LEG_SEVERITY = 'DOG_LEG_SEVERITY';
export const COMPLETION_LENGTH = 'COMPLETION_LENGTH';
export const WELL_COLL_DIST = 'WELL_COLLISION_DISTANCE';
export const WELL_FLOW_MIN_BHP = 'WELL_FLOWING_MIN_BHP';
export const WELL_FLOW_MIN_THP = 'WELL_FLOWING_MIN_THP';
export const MAX_WELL_GAS_RATE = 'MAX_WELL_GAS_RATE';
export const TYPICAL_TUBING_SIZE = 'TYPICAL_TUBING_SIZE';
export const UNIT_DEV_COST = 'UNIT_DEV_COST';
export const WELL_COST = 'WELL_COST';
export const FORCAST_TIME_PERIOD_YEARS = 'FORCAST_TIME_PERIOD_YEARS';

export const OPTIMIZING_CONSTRAINTS = 'optimizing constraints';
export const RATE_CONSTRAINT_TABLE = 'rate constraint table';
export const WELL_INFO_DF = 'well info df';
export const DATA_SMOOTHING_FACTOR = 'Data Smoothing Factor';
export const INTEGRATION = 'Integration';
export const OVERALL_DAYS = 30.5;

// Colnames
export const CORRELATION = 'Correlation';
export const LAG = 'Lag';
export const INJECTOR = 'Injector';
export const PRODUCER = 'Producer';
export const GAIN = 'Gain';
export const LOSS = 'Loss';
export const ERROR = 'Error';
export const RATE = 'Rate';
export const CUMULATIVE = 'Cumulative';
export const OIL = 'Oil';
export const WATER = 'Water';
export const GAS = 'Gas';
export const WAG = 'WAG';
export const LIQUID = 'Liquid';
export const LIQUID_RATE = 'Liquid rate';
export const OIL_RATE = 'Oil';
export const FLUID_RATE = 'Fluid';
export const DATA = ': Data';
export const CRM = ': CRM';
export const CRM2 = ': CRM2';
export const HISTORY_OPTIMIZATION = ' (History optimization)';
export const FUTURE_OPTIMIZATION = ' (Future optimization)';
export const WATER_RATE = 'Water rate';
export const CUMULATIVE_OIL = 'Cumulative Oil(rb)';
export const CUMULATIVE_WATER = 'Cumulative Water(Krb)';
export const OIL_LOSS = 'oil_loss';
export const WATER_LOSS = 'water_loss';
export const COST_SAVINGS = 'Cost Savings';
export const CHOKE_SIZE = 'Choke Size';
export const INITIAL_OIL_SATURATION = 'Initial Oil Saturation, Soi';
export const CURRENT_OIL_SATURATION = 'Current Oil Saturation, Sof';
export const ADDITIONAL_RECOVERY_PERCENT = 'Aditional Recovery, %';
export const ADDITIONAL_RECOVERY_M3 = 'Aditional Recovery, m3';
export const R2_WATER = 'R2 - water';
export const R2_OIL = 'R2 - oil';
export const WELL_STATUS = 'Well Status';
export const ORIGINAL = 'Original';
export const CALCULATED = 'Calculated';

export const LIGHTBLUE = 'rgb(135,206,250)';
export const GREEN = 'rgb(0,255,0)';
export const RED = 'rgb(255,0,0)';
export const YELLOW = 'rgb(255,255,0)';

export const FLUID_INJECTION_RATE = 'Fluid Injection Rate';
export const FLUID_PRODUCTION_RATE = 'Fluid Production Rate';
export const WATER_CUT = 'Water cut';
export const OIL_CUT = 'Oil cut';
export const WOR = 'WOR';
export const GOR = 'GOR';
export const YEARLY_WATER_CUT = 'Yearly Water Cut';
export const YEARLY_OIL_CUT = 'Yearly Oil Cut';
export const YEARLY_WOR = 'Yearly WOR';
export const YEARLY_GOR = 'Yearly GOR';
export const TUBING_HEAD_PRESSURE = 'Tubinghead Pressure';
export const NET_ALLOCATION = 'Net Allocation';

// Default Set Of Columns
export const PRODUCER_RATE_COLS = [
  OIL_PRODUCTION_RATE,
  WATER_PRODUCTION_RATE,
  GAS_PRODUCTION_RATE,
  FLUID_PRODUCTION_RATE,
];
export const INJECTOR_RATE_COLS = [
  WATER_INJECTION_RATE,
  GAS_INJECTION_RATE,
  FLUID_INJECTION_RATE,
];

export const ADDITIONAL_PRODUCER_RATE_COLS = [
  OIL_CUT,
  WATER_CUT,
  TUBING_HEAD_PRESSURE,
  BOTTOMHOLE_PRESSURE,
  ACTIVE_DAYS,
  GOR,
  WOR,
  CHOKE_SIZE,
  WELL_STATUS,
];

export const ADDITIONAL_INJECTOR_RATE_COLS = [
  TUBING_HEAD_PRESSURE,
  BOTTOMHOLE_PRESSURE,
  ACTIVE_DAYS,
  CHOKE_SIZE,
  WELL_STATUS,
];

export const COLUMNS_NORMALIZED = [
  ...PRODUCER_RATE_COLS,
  ...INJECTOR_RATE_COLS,
];

export const CUMULATIVE_PRODUCTION = 'Cumulative Production';
export const CUMULATIVE_GAS_PRODUCTION = 'Cumulative Gas Production';
export const CUMULATIVE_OIL_PRODUCTION = 'Cumulative Oil Production';
export const CUMULATIVE_WATER_PRODUCTION = 'Cumulative Water Production';
export const CUMULATIVE_INJECTION = 'Cumulative Injection';
export const CUMULATIVE_GAS_INJECTION = 'Cumulative Gas Injection';
export const CUMULATIVE_WATER_INJECTION = 'Cumulative Water Injection';
export const CUMULATIVE_PRODUCTION_COLS = {
  [CUMULATIVE_PRODUCTION]: TOTAL_PRODUCTION_RATE,
  [CUMULATIVE_OIL_PRODUCTION]: OIL_PRODUCTION_RATE,
  [CUMULATIVE_WATER_PRODUCTION]: WATER_PRODUCTION_RATE,
  [CUMULATIVE_GAS_PRODUCTION]: GAS_PRODUCTION_RATE,
};
export const CUMULATIVE_INJECTION_COLS = {
  [CUMULATIVE_INJECTION]: TOTAL_INJECTION_RATE,
  [CUMULATIVE_WATER_INJECTION]: WATER_INJECTION_RATE,
  [CUMULATIVE_GAS_INJECTION]: GAS_INJECTION_RATE,
};
export const CUMULATIVE_PRODUCTION_INJECTION_COLS = {
  ...CUMULATIVE_PRODUCTION_COLS,
  ...CUMULATIVE_INJECTION_COLS,
};
export const Y_FUNCTION = 'Y Function';
export const X_FUNCTION = 'X Function';
export const PRODUCTION_WOR_MONTHLY = 'Production WOR Monthly';
export const PRODUCTION_GOR_MONTHLY = 'Production GOR Monthly';
// ml utils
export const FEATURE_IMPORTANCE = 'Feature importance';
// ml prediction
export const SIZE = 'Size';
export const METHOD = 'Method';
export const PARAMETER = 'Parameter';
export const VAL_SCORE = 'Val_Score';
export const PREDICTION = 'Prediction';
export const TEST_SCORE = 'Test_Score';
export const TRAIN_SCORE = 'Train_Score';
export const OVERALL_SCORE = 'Overall_Score';
export const HISTORY_MATCH = 'History Match';
export const OUTPUT_DATA_HIST = 'Output_data_hst';
export const OUTPUT_DATA_PRD = 'Output_data_prd';
export const FUTURE_PREDICTION = 'Future prediction';

// Workflow Names
export const ARPS_DECLINE_CURVE = 'Arps Decline Curve';
export const CONFORMANCE = 'Conformance';
export const CRMP = 'CRMP';
export const CRMP_LAYER = 'CRMP Layer';
export const CRMT = 'CRMT';
export const CROSS_CORRELATION = 'Cross Correlation';
export const DIAGNOSTIC_PLOT = 'Diagnostic Plot';
export const ML_CONNECTIVITY = 'ML Connectivity';
export const ML_RATE_PREDICTION = 'ML Rate Prediction';
export const TREND_ANALYSIS = 'Trend Analysis';
export const CROSS_WORKFLOW_COMPARISON = 'Cross Workflow Comparison';
export const MULTI_WORKFLOW_COMPARISON = 'Multi Workflow Comparison';
export const POLY_INJECTION = 'Polymer Injection';

export const GREEN_FIELD = 'Green Field';
export const BROWN_FIELD = 'Brown Field';
export const FMM = 'FMM';
export const SOI = 'SOI';
export const HYBRID_MODEL = 'Hybrid Model';

export const ADVANCED_SETTINGS_LABELS = {
  [WELL_RATE_THRESHOLD]: 'Fraction of inactive days >=',
  [WATER_CUT_THRESHOLD]: 'Fraction of Maximum water cut <=',
  [ANGLE_THRESHOLD]: 'Angle Threshold',
  [MAX_ITERATION_FLUID_MATCHING]: 'Maximum Iterations',
  [TOLERANCE_FLUID_MATCHING]: 'Tolerance (1e-x)',
  [MAX_ITERATION_OIL_MATCHING]: 'Maximum Iterations',
  [TOLERANCE_OIL_MATCHING]: 'Tolerance (1e-x)',
  [MAX_ITERATION_OPTIMISATION]: 'Maximum Iterations',
  [TOLERANCE_OPTIMISATION]: 'Tolerance (1e-x)',
  [MAX_ITERATION_FUTURE_OPTIMISATION]: 'Maximum Iterations',
  [TOLERANCE_FUTURE_OPTIMISATION]: 'Tolerance (1e-x)',
  [TEST_SCORE]: 'Test Score',
  [MINIMUM_SIMULATIONS]: 'Minimum Simulations',
  [MAXIMUM_SIMULATIONS]: 'Maximum Simulations',
  [SIMULATION_COUNTER_SIZE]: 'Simulation Counter Size',
  [STEPOUT_DISTANCE]: 'Stepout Distance',
  [MAX_WELL_LENGTH]: 'Max. Well Length',
  [NUMBER_OF_ITERATIONS]: 'Number of Iterations',
  [WELL_FLOW_MIN_BHP]: 'Well flowing minimum bottom hole pressure (psi)',
  [WELL_FLOW_MIN_THP]: 'Well flowing minimum tubing head pressure (psi)',
  [MAX_WELL_GAS_RATE]: 'Max Well Gas Rate (mscf/day)',
  [TYPICAL_TUBING_SIZE]: 'Typical production tubing size (inch)',
  [UNIT_DEV_COST]: 'Unit Development Cost (UDC) per well (US$/MMboe)',
  [WELL_COST]: 'Well Cost Per Foot (USD/ft)',
  [DOG_LEG_SEVERITY]: 'Dogleg Severity (DLS) °',
  [COMPLETION_LENGTH]: 'Completion Length',
  [WELL_COLL_DIST]: 'Well Colision Distance Check',
  [FORCAST_TIME_PERIOD_YEARS]: 'Forecast time period (years)',
  [TRAIN_TEST_SPLIT]: 'Train Test Split (Train Size)',
  [SHUT_IN]: 'Shut In',
  [MIN_CONN]: 'Minimum Connection',
};
// Workflow Constants
export const DIAGNOSTIC_PLOTS_FIELD_TOTAL_RATE_COL_NAMES = {
  q_f: 'Field: Total Liquid Prod.',
  qw_f: 'Field: Total Water Prod.',
  qo_f: 'Field: Total Oil Prod.',
  qg_f: 'Field: Total Gas Prod.',
  i_f: 'Field: Total Liquid Inj.',
  iw_f: 'Field: Total Water Inj',
  ig_f: 'Field: Total Gas Inj.',
};
export const DIAGNOSTIC_PLOTS_FIELD_RATIO_COL_NAMES = {
  ivrr: 'IVRR',
  fw_f: 'Water Cut',
  fo_f: 'Oil Cut',
  Y_f: 'Y',
  X_f: 'X',
  WOR_f: 'Water Oil Ratio',
  GOR_f: 'Gas Oil Ratio',
};
export const DIAGNOSTIC_PLOTS_LIQUID_INJ_PROD_RATE_COL_NAMES = {
  N_f: 'Field: Cum. Prod.',
  Nw_f: 'Field: Cum. Water Prod.',
  No_f: 'Field: Cum. Oil Prod.',
  Ng_f: 'Field: Cum Gas Prod.',
  I_f: 'Field: Cum. Inj.',
  Iw_f: 'Field: Cum. Water Inj.',
  Ig_f: 'Field: Cum. Gas Inj.',
  cvrr: 'CVRR',
};
