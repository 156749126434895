import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Papa from 'papaparse';

import {
  Collapse,
  Grid,
  withStyles,
  Typography,
  TextField,
  Button,
  Box,
  LinearProgress,
} from '@material-ui/core';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';

import useStyles from '../../Styles/DropfileZoneStyle';
import { addDataset } from '../../redux/actions/dataset';
import { updateSnackBar } from '../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../constants/ComponentConstants';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#95d5b2',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2d6a4f',
  },
}))(LinearProgress);

const LinearProgressWithLabel = props => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

const LoadingBarComponent = ({ updateLoadingBar, updateProgress, value }) => {
  const styles = useStyles();

  // This effect collapses loading bar once it hits 100% on redux state.
  useEffect(() => {
    async function humanFriendlyResponse() {
      await new Promise(resolve => setTimeout(resolve, 1000));
      updateLoadingBar(false);
      await new Promise(resolve => setTimeout(resolve, 500));
      updateProgress(0);
    }

    if (value == 100) {
      humanFriendlyResponse();
    }
  }, [value]);

  return (
    <Grid item container className={styles.loadingPage}>
      <Grid item className="text">
        <Typography className="descriptionText">Loading...</Typography>
      </Grid>
      <Grid item className="bar">
        <LinearProgressWithLabel style={{ width: '100%' }} value={value} />
      </Grid>
    </Grid>
  );
};

LoadingBarComponent.propTypes = {
  updateLoadingBar: PropTypes.func,
  updateProgress: PropTypes.func,
  value: PropTypes.number,
};

const DatasetArgumentsComponent = ({
  datasetType,
  acceptedFile,
  updateLoadingBar,
  updateDatasetDetails,
  updateProgress,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [datasetName, setdatasetName] = useState('');
  const [datasetDescription, setdatasetDescription] = useState('');

  const onNameChange = event => {
    setdatasetName(event.target.value);
  };
  const onDescriptionChange = event => {
    setdatasetDescription(event.target.value);
  };

  /**
   * This callback function is used to trach the uploaded file progress on the front end.
   * @param {Event} progressEvent
   */
  const onFileUploadProgress = progressEvent => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    updateProgress(percent);
  };

  // Save dataset with given name, description, and file to server by dispatch.
  const onSubmit = async () => {
    if (datasetName != '' && datasetDescription != '') {
      try {
        const formData = new FormData();
        formData.append('name', datasetName);
        formData.append('description', datasetDescription);
        formData.append('file', acceptedFile);
        formData.append('dataset_type', datasetType);

        // Collapse dataset details component.
        updateDatasetDetails(false);
        setdatasetName('');
        setdatasetDescription('');

        // Human friendly reaction and expand loading bar component.
        await new Promise(resolve => setTimeout(resolve, 500));
        updateLoadingBar(true);

        await dispatch(addDataset(formData, onFileUploadProgress));
        dispatch(
          updateSnackBar(
            'Dataset created successfully.',
            SNACK_BAR_SEVERITY.success
          )
        );
      } catch (error) {
        updateLoadingBar(false);
      }
    }
  };

  useEffect(() => {
    acceptedFile && 'name' in acceptedFile && setdatasetName(acceptedFile.name);
  }, [acceptedFile]);

  return (
    <Grid item container className={styles.fileArguments}>
      <Grid item container className="textInput">
        <Typography className="description">Dataset Name</Typography>
        <TextField
          value={datasetName}
          className="field"
          placeholder="Required"
          size="small"
          variant="outlined"
          onChange={onNameChange}
          inputProps={{
            style: {
              fontSize: '0.875em',
            },
          }}
        />
      </Grid>
      <Grid item container className="textInput">
        <Typography className="description">Dataset Description</Typography>
        <TextField
          value={datasetDescription}
          className="field"
          placeholder="Required"
          size="small"
          variant="outlined"
          onChange={onDescriptionChange}
          inputProps={{
            style: {
              fontSize: '0.875em',
            },
          }}
        />
      </Grid>
      <Grid item container className="buttonField">
        <Button
          variant="contained"
          className="button"
          onClick={() => updateDatasetDetails(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="button"
          onClick={onSubmit}
          disabled={
            datasetName == '' || datasetDescription == '' ? true : false
          }
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

DatasetArgumentsComponent.propTypes = {
  datasetType: PropTypes.string,
  acceptedFile: PropTypes.object,
  updateLoadingBar: PropTypes.func,
  updateDatasetDetails: PropTypes.func,
  updateProgress: PropTypes.func,
};

const DropfileZone = ({ datasetType }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [loadingBar, setloadingBar] = useState(false);
  const [showDatasetDetails, setshowDetdatasetDetails] = useState(false);
  const [acceptedFile, setacceptedFile] = useState(null);
  const [progressPercent, setprogressPercent] = useState(0);

  const updateLoadingBar = value => {
    setloadingBar(value);
  };
  const updateDatasetDetails = value => {
    setshowDetdatasetDetails(value);
  };
  const updateProgress = value => {
    setprogressPercent(value);
  };

  function checkWellData(list, errors) {
    let previousWell = null;
    let previousDate = null;
    let wellNamesSet = new Set();

    for (let i = 0; i < list.length; i++) {
      let currentWell = list[i]['Well Name'];
      let currentDate = new Date(list[i]['Date']);

      // Check for non-contiguous well names
      if (
        previousWell &&
        currentWell === previousWell &&
        currentDate < previousDate
      ) {
        if (!errors.has(2)) {
          errors.set(2, {
            description: 'Dates are not in increasing order',
            additionalInfo: [],
          });
        }
        errors.get(2).additionalInfo.push(`${currentWell} - ${i + 2}`);
        return;
      }

      if (
        previousWell &&
        currentWell !== previousWell &&
        wellNamesSet.has(currentWell)
      ) {
        if (!errors.has(3)) {
          errors.set(3, {
            description: 'Non-contiguous well name found',
            additionalInfo: [],
          });
        }
        errors.get(3).additionalInfo.push(`${currentWell} - ${i + 2}`);
        return;
      }

      wellNamesSet.add(currentWell);
      previousWell = currentWell;
      previousDate = currentDate;
    }
  }

  function isValidDate(dateString) {
    const regex =
      /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})$/;
    return regex.test(dateString);
  }

  const validateFile = (acceptedFile, onComplete) => {
    /* eslint-disable no-console */
    console.log(acceptedFile + 'file validation started......');
    /* eslint-enable no-console */
    Papa.parse(acceptedFile, {
      complete: result => {
        let rows = result.data;
        /* eslint-disable no-console */
        console.log(rows[0]['Well Name']);
        /* eslint-enable no-console */
        let errors = new Map();
        let warnings = new Map();
        //create error set and warning set
        //1st rule - check dash exist in well names, if found correct the file, add to warning
        //2nd rule - use a set to keep the well name and check dates are increasing sequence, add to error
        //3rd rule - use above set to iterate the rows and if wellnames are scattered, add to error
        //4th rule - check date format is correct if not add to error
        for (const row of rows) {
          checkWellData(rows, errors);
          if (!isValidDate(row['Date'])) {
            if (!errors.has(4)) {
              errors.set(4, {
                description: "Date does not match format '%m/%d/%Y'",
                additionalInfo: [],
              });
            }
            errors.get(4).additionalInfo.push(`${row['Date']}`);
          }
          if (row['Well Name'] && row['Well Name'].includes('-')) {
            if (!warnings.has(1)) {
              warnings.set(1, {
                description:
                  "Some Well names has '-' character. Those were replaced with '_' charater",
                additionalInfo: '',
              });
            }
            row['Well Name'] = row['Well Name'].replace(/-/g, '_');
          }
        }
        const updatedCSV = Papa.unparse(rows);
        onComplete(updatedCSV, warnings, errors);
      },
      header: true, // If you want to include the headers
    });
  };

  /**
   * This is a handler function for useDropzone usecallback, it shows accepted and rejected files
   * when user tries to upload files via dropzone.
   *
   * @param {Array} acceptedFiles
   * @param {Array} fileRejections
   */
  const onDrop = (acceptedFiles, fileRejections) => {
    /* eslint-disable no-console */
    console.log('dropped a file to upload');
    console.log(acceptedFiles);
    console.log(fileRejections);

    // Validate the dropped file
    validateFile(acceptedFiles[0], (updatedCSV, warnings, errors) => {
      console.log('Validation complete');

      // Create a new Blob from the updated CSV string and set it as the new file
      const updatedFile = new Blob([updatedCSV], { type: 'text/csv' });

      // If you need to keep file name and metadata, we can create a File object
      const updatedFileWithMetadata = new File(
        [updatedFile],
        acceptedFiles[0].name,
        {
          type: acceptedFiles[0].type,
        }
      );

      console.log('Modified file:', updatedFileWithMetadata);
      console.log('errors - ' + JSON.stringify(Array.from(errors)));
      console.log('warnings - ' + JSON.stringify(Array.from(warnings)));

      // Proceed with your logic for handling the file upload
      if (acceptedFiles.length > 0 && errors.size == 0) {
        setacceptedFile(updatedFileWithMetadata); // Save the updated file
        setshowDetdatasetDetails(true); // Show dataset details if needed
        if (warnings.size > 0) {
          dispatch(
            updateSnackBar(
              warnings.get(1).description,
              SNACK_BAR_SEVERITY.warning
            )
          );
        }
      } else {
        if (warnings.size > 0) {
          dispatch(
            updateSnackBar(
              warnings.get(1).description,
              SNACK_BAR_SEVERITY.warning
            )
          );
        }
        if (errors.size > 0) {
          for (const [key, value] of errors) {
            let description = '';
            if (key == 2 || key == 3) {
              description = `${value.description}.
               For ex: well ${value.additionalInfo[0]} index`;
            }
            if (key == 4) {
              description = `${value.description}.
               For ex: Date ${value.additionalInfo[0]}`;
            }
            dispatch(updateSnackBar(description, SNACK_BAR_SEVERITY.error));
          }
        }
        if (acceptedFiles.length == 0) {
          dispatch(
            updateSnackBar(
              `Dropped file, ${fileRejections[0].name} rejected.`,
              SNACK_BAR_SEVERITY.error
            )
          );
        }
      }
    });
    // if (acceptedFiles.length > 0) {
    //   setacceptedFile(acceptedFiles[0]);
    //   setshowDetdatasetDetails(true);
    // } else {
    //   dispatch(
    //     updateSnackBar(
    //       `Dropped file, ${fileRejections[0].name} rejected.`,
    //       SNACK_BAR_SEVERITY.error
    //     )
    //   );
    // }
    /* eslint-enable no-console */
  };

  // init. callback
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['.csv'],
    onDrop,
  });

  return (
    <Grid container className={styles.root}>
      <Grid item container className={styles.dropzone} {...getRootProps()}>
        <input {...getInputProps({ onDrop })} />
        <DescriptionRoundedIcon className="icon" />
        <Typography className="descriptionText">
          Drop your file here, or click to browse
        </Typography>
        <Typography className="footerText">Supports .csv files only</Typography>
      </Grid>

      <Collapse in={showDatasetDetails}>
        <DatasetArgumentsComponent
          datasetType={datasetType}
          acceptedFile={acceptedFile}
          updateLoadingBar={updateLoadingBar}
          updateDatasetDetails={updateDatasetDetails}
          updateProgress={updateProgress}
        />
      </Collapse>

      <Collapse in={loadingBar}>
        <LoadingBarComponent
          updateLoadingBar={updateLoadingBar}
          updateProgress={updateProgress}
          value={progressPercent}
        />
      </Collapse>
    </Grid>
  );
};

DropfileZone.propTypes = {
  datasetType: PropTypes.string,
};

export default DropfileZone;
