import React from 'react';

import { Typography } from '@material-ui/core';
import { BasicTable } from '../TableView';

import {
  BOTTOMHOLE_PRESSURE,
  DATE,
  GAS_INJECTION_RATE,
  GAS_PRODUCTION_RATE,
  IS_INJECTOR_WELL,
  OIL_PRODUCTION_RATE,
  WATER_INJECTION_RATE,
  WATER_PRODUCTION_RATE,
  WELL_ID,
  XCOL,
  YCOL,
} from '../../../constants/WellConstants';

const WorkflowDataFormat = () => {
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom={true}>
        This module accepts a csv file as an input data. User can upload the csv
        file with the below format,
      </Typography>
      <BasicTable
        data={[
          {
            [DATE]: '...',
            [WELL_ID]: '...',
            [XCOL]: '...',
            [YCOL]: '...',
            [IS_INJECTOR_WELL]: '...',
            [OIL_PRODUCTION_RATE]: '...',
            [WATER_PRODUCTION_RATE]: '...',
            [GAS_PRODUCTION_RATE]: '...',
            [WATER_INJECTION_RATE]: '...',
            [GAS_INJECTION_RATE]: '...',
            [BOTTOMHOLE_PRESSURE]: '...',
          },
        ]}
      />
      <br />
      <Typography variant="body1" gutterBottom={true}>
        If not given, Bottomhole Pressure = 1500 is substituted once the data is
        read
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Take care of the below Additional Requirement:
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Monthly data, Below rates(Oil Production Rate,Water Production Rate,Gas
        Production Rate, Water Injection Rate, and Gas Injection Rate) to be in
        rb/month, Consistent Surface X and Surface Y for identical wells
        strictly in rb/month.
      </Typography>
    </React.Fragment>
  );
};

export default WorkflowDataFormat;
